import { types } from "mobx-state-tree";

const OptionsModel = types
  .model("OptionsModel", {
    id: types.string,
    name: types.string,
    selected: false
  })
  .actions(self => ({
    toggle() {
      self.selected = !self.selected;
    },
    setSelected(value) {
      self.selected = value;
    }
  }));

const FilterModel = types
  .model("FilterModel", {
    type: types.string,
    name: types.string,
    label: types.string,
    hasCheckAll: false,
    value: types.union(
      types.string,
      types.array(types.string),
      types.undefined,
      types.null
    ),
    options: types.optional(types.array(OptionsModel), [])
  })

  .actions(self => ({
    afterCreate() {
      if (self.value === null || self.value === undefined)
        self.value = self.type === "checkboxgroup" ? [] : "";
    },

    update(value) {
      if (self.type === "checkboxgroup") {
        let selectedOptions = self.options.filter(o => o.selected === true);
        self.value = selectedOptions.map(o => o.id);
      } else {
        self.value = value;
      }
    },

    initializeValue(value) {
      if (value === null || value === undefined) {
        return;
      }

      if (value === "all" && self.type === "checkboxgroup") {
        value = self.options.map(opt => opt.id);
      } else if (typeof value === "number") {
        value = value.toString();
      } else if (Array.isArray(value)) {
        value = value.map(value => value.toString());
      }
      self.value = value;
      if (self.type === "checkboxgroup") {
        self.options.forEach(o => {
          if (Array.isArray(self.value) && self.value.indexOf(o.id) !== -1) {
            o.setSelected(true);
          }
        });
      }
    },

    setOptions(val) {
      self.options = val;
    }
  }))

  .views(self => ({
    isAllOptionsSelected() {
      if (self.type !== "checkboxgroup" || self.options.length < 1) {
        return false;
      }

      return (
        self.options.filter(o => o.selected === true).length ===
        self.options.length
      );
    }
  }));

export default FilterModel;
