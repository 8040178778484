/**
 * useAlertCounter.js
 *
 * Handles the seconds countdown displayed in the logout alert
 */
import { useState, useEffect } from "react";

// closes dialog after x amount of seconds and auto logs out the user
const secondsToClose = 60;

const useAlertCounter = () => {
  // init var for interval
  let tick;

  // local state
  let [count, setCount] = useState(secondsToClose);

  useEffect(() => {
    return () => clearInterval(tick);
  });

  // decrement the seconds until it hits zero
  let start = () => {
    tick = setInterval(() => {
      if (count > 0) setCount(count - 1);
    }, 1000);
  };

  // resets the counter
  let reset = () => {
    clearInterval(tick);

    // prevents the counter from resetting before the alert is closed
    setTimeout(() => {
      setCount(secondsToClose);
    }, 2000);
  };

  return {
    count,
    start,
    reset
  };
};

export default useAlertCounter;
