/**
 * OrdersHandler.js
 *
 * Attaches orders state to a child component to render orders on the dashboard
 */
import React from "react";
import PropTypes from "prop-types";
import useOrders from "../state/useOrders";

const OrdersHandler = ({ children }) => {
  let state = useOrders();
  return React.cloneElement(children, state);
};

OrdersHandler.propTypes = {
  children: PropTypes.node
};

export default OrdersHandler;
