/**
 * ReferralPhoneNumbers.js
 *
 * Renders referral phone numbers
 */
import React from "react";
import PropTypes from "prop-types";
import UIPhoneLink from "components/ui/UIPhoneLink";
import UIListItem from "components/ui/UIListItem";

const extension = phone => {
  let string = phone.phone;
  if (phone.ext) string = string + " ext. " + phone.ext;
  return <UIPhoneLink phone={string} />;
};

const Phone = ({ phone }) => (
  <UIListItem label={phone.type} value={extension(phone)} />
);

const ReferralPhoneNumbers = ({ referral }) => (
  <React.Fragment>
    {referral.phone1 && <Phone phone={referral.phone1} />}
    {referral.phone2 && <Phone phone={referral.phone2} />}
    {referral.phone3 && <Phone phone={referral.phone3} />}
  </React.Fragment>
);

ReferralPhoneNumbers.propTypes = {
  referral: PropTypes.object
};

export default ReferralPhoneNumbers;
