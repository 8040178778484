import { Component } from "react";
import { observer } from "mobx-react";

class DiagnosisHandler extends Component {
  render() {
    return this.props.render(
      this.props.store.selectedDiagnoses,
      this.props.store.deleteFormDiagnosis
    );
  }
}

export default observer(DiagnosisHandler);
