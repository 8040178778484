/**
 * Standalone app will reopen app to index.html so we want to redirect
 * to the last page they visited
 * (which is being stored in localStorage from Router.js)
 */

import { LastPageStore } from "./LastPageStore";

/**
 * Check to see if index.html exists in the url
 *
 * @return {Boolean}
 */
const isIndex = () => window.location.toString().search("index.html") !== -1;

/**
 * Creates the redirect route for the app to use
 *
 * @return {String}
 */
const redirectTo = () => {
  // create an array from the pathname
  let parts = window.location.pathname.split("/");

  // get just the route before index.html (e.g. somepath/index.html)
  let baseRoute = parts.slice(parts.length - 2);

  // create a new array from the baseRoute to replace the pathname in the redirect
  let baseRouteArray = baseRoute.toString().split(",");

  let redirect = LastPageStore.getLastPage() || "dashboard";
  return redirect.replace(baseRouteArray[0], "", redirect);
};

const CheckIndex = {
  isIndex: isIndex,
  redirectTo: redirectTo
};

export default CheckIndex;
