class RootDir {
  constructor() {
    this.setBaseName();
  }

  setBaseName = () => {
    if (process.env.NODE_ENV === "development") {
      this.path = "";
      return;
    }

    let element = document.createElement("a");
    element.href = process.env.PUBLIC_URL;
    let baseName = element.pathname;
    if (baseName.slice(-1) === "/") baseName = baseName.slice(0, -1);
    this.path = baseName;
  };

  check() {
    if (!this.path) {
      this.setBaseName();
    }
  }
}

const rootDir = new RootDir();
export default rootDir;
