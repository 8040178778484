import { types, getSnapshot } from "mobx-state-tree";
import FilterModel from "./FilterModel";

const FilterStore = types
  .model("FilterStore", {
    fields: types.array(FilterModel)
  })
  .actions(self => ({
    mapDataToFormFields(data) {
      self.fields.forEach(field => field.initializeValue(data[field.name]));
    }
  }))
  .views(self => ({
    getFields() {
      return getSnapshot(self.fields);
    }
  }));

export default FilterStore;
