import { types, flow } from "mobx-state-tree";
import ProviderOfficeFeeDataModel from "./ProviderOfficeFeeDataModel";
import Api from "./ProviderOfficeFeeApi";
import PatientIdStore from "services/patients/store/PatientIdStore";

const ProviderOfficeFeeModel = types
  .model("ProviderOfficeFeeDataModel", ProviderOfficeFeeDataModel)

  .actions(self => ({
    update(key, val) {
      let newVal = Number(val);
      let submodel = self[key];
      if (key === "accountProvider" || key === "patientProvider")
        submodel["userId"] = newVal;
      else if (key === "patientOffice" || key === "feeSchedule")
        submodel["id"] = newVal;
    },

    getProviderOfficeFeeData: flow(function*() {
      let patientId = PatientIdStore.getId();
      let results;
      try {
        results = yield Api.getProviderOfficeFee(patientId);
      } catch (error) {
        console.log("Error fetching patient", error);
      }
      if (results) {
        self.patientProvider = results.patientProvider;
        self.accountProvider = results.accountProvider;
        self.patientOffice = results.patientOffice;
        self.feeSchedule = results.feeSchedule;
        self.accountOffice = results.accountOffice;
      }
    }),

    updateProviderOfficeFee: flow(function*(patientId) {
      let requestObject = {
        patientProviderId: self.patientProvider.userId,
        accountProviderId: self.accountProvider.userId,
        officeId: self.patientOffice.id,
        feeScheduleId: self.feeSchedule.id
      };
      try {
        return yield Api.updateProviderOfficeFee(patientId, requestObject);
      } catch (error) {
        console.log("Error updating Provider, Office, & Fee Schedule");
      }
    })
  }));

export default ProviderOfficeFeeModel;
