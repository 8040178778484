const SecurityPreferences = [
  {
    label: "Security",
    type: "header"
  },
  {
    name: "autoLogout",
    label: "Auto Logout",
    type: "radiogroup",
    value: "15",
    options: [
      {
        id: 5,
        name: "5 Minutes"
      },
      {
        id: 10,
        name: "10 Minutes"
      },
      {
        id: 15,
        name: "15 Minutes"
      },
      {
        id: 20,
        name: "20 Minutes"
      },
      {
        id: 25,
        name: "25 Minutes"
      },
      {
        id: 30,
        name: "30 Minutes"
      }
    ]
  }
];

export default SecurityPreferences;
