import { types } from "mobx-state-tree";

/**
 * A "hydrated" preference value. If the preference value loaded from the database is not detailed enough to use directly, the work to construct a more detailed value should typically be performed up front (e.g. loading a model from an ID) instead of just storing the ID.
 *
 * See one of the concrete Preference types for the interface used to load and update preferences. (Mobx State Tree doesn't have a concept of extending an interface for models, so they currently just all use compatible action methods.)
 */
export const PreferenceBase = types
  .model("Preference", {
    /** The key used to lookup the preference in the map. */
    internalKey: types.maybe(types.string),
    /** The preference key string */
    pkey: types.string
  })
  .views(self => ({
    get key() {
      if (self.internalKey !== undefined) {
        return self.internalKey;
      } else {
        return self.pkey;
      }
    }
  }));
