import { Http } from "api/Http";
import PatientIdStorage from "services/patients/store/PatientIdStore";

/*
// hide when not using to avoid lint warning
let mockedAlerts = {
  patient: [
    {
      title: "Diabetic",
      date: "1998-05-28T16:28:15Z"
    },
    {
      title: "New Last Name",
      date: "2017-12-31T16:28:15Z"
    }
  ],
  account: [
    {
      title: "In Collections",
      date: "2017-11-15T16:28:15Z"
    }
  ],
  allergy: [
    {
      title: "12-hydroxystearic acid",
      date: "2017-12-03T16:28:15Z"
    },
    {
      title: "Promethegan",
      date: "2014-21-03T14:28:15Z"
    },
    {
      title: "Zytiga",
      date: "2000-05-31T16:28:15Z"
    }
  ]
};
*/
let Api = new Http("patients/1/patient");

class AlertsApi {
  constructor(api) {
    this.api = api;
  }

  fetchPatientAlerts = async id => {
    let patientId = id || PatientIdStorage.getId();
    let res = await this.api.get("/" + patientId + "/alerts");
    return res.data || [];
    // return { alerts: mockedAlerts };
  };

  fetchAccountAlerts = async id => {
    let patientId = id || PatientIdStorage.getId();
    let res = await this.api.get("/" + patientId + "/accountAlerts");
    return res.data || [];
  };

  fetchAllergyAlerts = async id => {
    let patientId = id || PatientIdStorage.getId();
    let res = await this.api.get("/" + patientId + "/allergy");
    return res.data || [];
  };
}

const newApi = new AlertsApi(Api);
export default newApi;
