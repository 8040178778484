/**
 * Component that returns a colored dot for the order card
 * based on the status of the order
 */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// status id's that are flagged as "available"
const availableStatuses = [9];

const OrderStatusIndicator = ({ classes, statusId }) => {
  let statusClass = availableStatuses.includes(statusId) ? "available" : "open";
  let className = classes.orderStatus + " " + classes[statusClass];
  return <span className={className} />;
};

const styles = {
  orderStatus: {
    borderRadius: 100,
    padding: 4,
    background: "red",
    display: "inline-block"
  },
  open: {
    background: "red"
  },
  available: {
    background: "blue"
  }
};

OrderStatusIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  statusId: PropTypes.number
};

export default withStyles(styles)(OrderStatusIndicator);
