import { types } from "mobx-state-tree";
import { addFilterValueToParams, FilterValue } from "./FilterValue";

/**
 * An MST type representing an orders filters preference.
 */
export const OrderFilters = types
  .model("OrderFilters", {
    offices: FilterValue,
    statuses: FilterValue,
    providers: FilterValue,
    types: FilterValue,
    signed: types.union(types.boolean, types.literal("both"))
  })
  .views(self => ({
    getUrl(queryParameters) {
      addFilterValueToParams(self.offices, "offices", queryParameters);
      addFilterValueToParams(self.providers, "providers", queryParameters);

      addFilterValueToParams(self.statuses, "statuses", queryParameters);
      addFilterValueToParams(self.types, "types", queryParameters);

      if (self.signed === "both") {
        // "both" state is represented by not adding a query parameter
      } else if (self.signed === true) {
        signed(queryParameters);
      } else {
        unsigned(queryParameters);
      }
    }
  }));

function unsigned(queryParameters) {
  queryParameters.append("unsigned", "1");
}

function signed(queryParameters) {
  queryParameters.append("signed", "1");
}
