/**
 * Univeral link to the patient homepage.  We can add user privs here
 * or change the default patient page
 *
 * Example: <UIPatientLink id={patientId} name={patientName} />
 *
 * @author Rob Rothe
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UIPatientLink = ({ id, name }) => {
  let linkTo = "/patients/" + id + "/patient_home";
  return <Link to={linkTo}>{name}</Link>;
};

UIPatientLink.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string
};

export default UIPatientLink;
