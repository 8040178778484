/**
 * Routes.js
 *
 * Routes for referrals
 */
import Search from "./views/Search";
import Referral from "./views/Referral";

export default [
  {
    path: "/referrals",
    exact: true,
    component: Search
  },
  {
    path: "/referrals/:referralId",
    exact: true,
    component: Referral
  }
];
