import { Http } from "api/Http";

const Api = new Http("patients/1/patient");

class EmergencyContactsAPI {
  constructor(api) {
    this.api = api;
  }

  getEmergencyContacts = async patientId => {
    let res = await this.api.get("/", patientId + "/emergencyContacts");
    return res.data || {};
  };
}

const newApi = new EmergencyContactsAPI(Api);
export default newApi;
