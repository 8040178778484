const SchedulingPreferences = [
  {
    label: "Schedule",
    type: "header"
  },
  {
    name: "apptColors",
    label: "Show Appointment Display Colors",
    type: "switch",
    value: true
  }
];

export default SchedulingPreferences;
