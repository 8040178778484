/**
 * Appointments.js
 *
 * HOC for rendering appointments
 */
import React from "react";
import PropTypes from "prop-types";
import ApptIcon from "@material-ui/icons/DateRange";
import DashboardCard from "./DashboardCard";

const Appointments = ({ loading, appts }) => (
  <DashboardCard
    fetched={loading}
    label="Appointments Scheduled"
    icon={<ApptIcon className="dashboard-card-icon" />}
    number={appts.length}
    url="/appointments"
  />
);

Appointments.propTypes = {
  // indicator for show/hide spinner
  loading: PropTypes.bool,
  appts: PropTypes.array
};

export default Appointments;
