import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

class UIAlertDialog extends React.Component {
  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    let { errorTitle, errorMessage, buttonTitle } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              {buttonTitle ?? "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UIAlertDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string
};

export default UIAlertDialog;
