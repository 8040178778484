/**
 * Store.js
 *
 * Fetches alerts for a patient and persists the results here so we don't
 * need to fetch them on each patient sub route
 */
import Api from "./Api";
import { flow, types } from "mobx-state-tree";
import DateFormatter from "utils/DateFormatter";

const SharedAlertViews = self => ({
  get dateString() {
    return self.created ? DateFormatter.mdy(self.created) : "";
  },
  get expirationString() {
    return self.expiration ? DateFormatter.mdy(self.expiration) : "";
  }
});

const AllergyAlert = types
  .model("AllergyAlert", {
    description: types.string,
    allergyType: types.string,
    allergyId: types.maybe(types.number),
    severity: types.string,
    reaction: types.maybe(types.string),
    status: types.string,
    notes: types.maybe(types.string),
    onset: types.maybe(types.string),
    created: types.maybe(types.string)
  })
  .views(SharedAlertViews);

const AccountAlert = types
  .model("AccountAlert", {
    alertId: types.maybe(types.number),
    description: types.string,
    created: types.string,
    expiration: types.maybe(types.string)
  })
  .views(SharedAlertViews);

const PatientAlert = types
  .model("PatientAlert", {
    alertId: types.number,
    description: types.string,
    created: types.maybe(types.string),
    alertNumber: types.number,
    isPreventiveCare: types.maybe(types.boolean)
  })
  .views(SharedAlertViews);

const Alerts = types.model("PatientAlerts", {
  patient: types.array(PatientAlert),
  account: types.array(AccountAlert),
  allergy: types.array(AllergyAlert)
});

const Store = types
  .model("PatientAlertsStore", {
    // This default is required because MST doesn't appear to use the default values of nested types automatically
    alerts: types.optional(Alerts, () => ({})),
    fetched: false,
    patientId: types.maybeNull(types.number),
    alertDialogOpen: false,
    loading: false
  })
  .actions(self => ({
    fetchAlerts: flow(function*(patientId) {
      if (self.fetched === true && self.patientId === patientId) {
        return new Promise(resolve => resolve(self.alerts));
      }
      self.loading = true;
      self.alerts = undefined;
      self.patientId = patientId;
      self.alerts.patient = yield Api.fetchPatientAlerts(patientId);
      self.alerts.account = yield Api.fetchAccountAlerts(patientId);
      self.alerts.allergy = yield Api.fetchAllergyAlerts(patientId);
      self.fetched = true;
      self.loading = false;
      return self.alerts;
    }),
    toggleDialog: () => {
      self.alertDialogOpen = !self.alertDialogOpen;
    },
    invalidate: () => {
      self.alerts = undefined;
      self.fetched = false;
      self.patientId = null;
    }
  }))
  .views(self => ({
    get alertString() {
      const alerts = self.alerts;

      let alertSegments = [];
      let totalLength = 0;
      const patientLength = alerts.patient.length;
      if (alerts.patient && patientLength) {
        totalLength += patientLength;
        alertSegments.push(patientLength + " Patient");
      }
      const accountLength = alerts.account.length;
      if (alerts.account && accountLength) {
        totalLength += accountLength;
        alertSegments.push(accountLength + " Account");
      }
      const allergyLength = alerts.allergy.length;
      if (alerts.allergy && allergyLength) {
        totalLength += allergyLength;
        alertSegments.push(allergyLength + " Allergy");
      }

      if (alertSegments.length > 0) {
        let fullString;
        if (alertSegments.length === 1) {
          fullString = alertSegments[0] + " Alert";
        } else {
          fullString =
            alertSegments.slice(0, -1).join(", ") +
            ", and " +
            alertSegments[alertSegments.length - 1] +
            " Alert";
        }

        if (totalLength > 1) {
          fullString += "s";
        }

        return fullString;
      }
      return null;
    }
  }));

export default Store;
