import { types } from "mobx-state-tree";
import { formatISO, parseISO } from "date-fns";
import isValid from "date-fns/isValid";

/**
 * An MST type which parses an ISO date string from it's snapshot representation and stores a standard date type in the model. When the model is transformed back into a snapshot the Date will be converted back to an ISO date string.
 */
export const DateFromString = types.custom({
  name: "DateFromString",
  fromSnapshot(snapshot: string): Date {
    const parsed = parseISO(snapshot);
    if (isValid(parsed)) {
      return parsed;
    } else {
      throw new Error(`Invalid snapshot date`);
    }
  },
  toSnapshot(value: Date): string {
    return formatISO(value);
  },
  isTargetType(value: Date | string): value is Date {
    return value instanceof Date;
  },
  getValidationMessage(snapshot: string): string {
    const parsed = parseISO(snapshot);
    if (isValid(parsed)) {
      return "";
    } else {
      return `${snapshot} could not be parsed as an ISO date.`;
    }
  }
});
