/**
 * Search.js
 *
 * HOC for search UI
 */
import React from "react";
import SearchHandler from "../handlers/SearchHandler";
import Search from "./components/Search";

const SearchView = () => (
  <SearchHandler>
    <Search />
  </SearchHandler>
);

export default SearchView;
