/**
 * AlertDialog.js
 *
 * Renders a dialog with patient alerts
 */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import AlertList from "./AlertList";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import AlertStore from "services/patients/alerts/Store";

const AlertDialog = () => {
  const { alertDialogOpen: open, toggleDialog: onToggle } = useStore(
    AlertStore
  );
  return (
    <Dialog open={open} scroll="paper" fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <AlertList />
      </DialogContent>
      <DialogActions>
        <Button onClick={onToggle} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(AlertDialog);
