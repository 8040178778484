import { Http } from "api/Http";

const Api = new Http("patients/1/patient");

class InsuranceAPI {
  constructor(api) {
    this.api = api;
  }

  getInsurance = async patientId => {
    let res = await this.api.get("/", patientId + "/insurance");
    return res.data || {};
  };

  getGuarantors = async patientId => {
    let res = await this.api.get("/", patientId + "/guarantors");
    return res.data || {};
  };
}

const newApi = new InsuranceAPI(Api);
export default newApi;