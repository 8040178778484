/**
 * Search.js
 *
 * Renders components to do a referral search
 */
import React from "react";
import PropTypes from "prop-types";
import UIProgress from "components/ui/UIProgress";
import UINotFound from "components/ui/UINotFound";
import UISearch from "components/ui/UISearch";
import SearchResults from "./SearchResults";

const Search = ({
  keyword,
  searching,
  doneSearching,
  referrals,
  onChange,
  onSubmit
}) => (
  <div>
    <div className="sub-toolbar">
      <UISearch
        autoFocus={true}
        placeholder="Search referrers..."
        keyword={keyword}
        parentOnChange={onChange}
        parentOnSubmit={onSubmit}
      />
    </div>

    {searching && <UIProgress />}
    {doneSearching && !referrals.length && (
      <UINotFound message="No referrers were found." />
    )}
    {referrals && <SearchResults referrals={referrals} />}
  </div>
);

Search.propTypes = {
  keyword: PropTypes.string,
  searching: PropTypes.bool,
  doneSearching: PropTypes.bool,
  referrals: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default Search;
