/**
 * ReferralNotes.js
 *
 * Renders referral notes
 */
import React from "react";
import PropTypes from "prop-types";
import UINotFound from "components/ui/UINotFound";

const ReferralNotes = ({ notes }) => (
  <React.Fragment>
    {!notes && <UINotFound message="No Referrer Notes" />}
    {notes && <div>{notes}</div>}
  </React.Fragment>
);

ReferralNotes.propTypes = {
  notes: PropTypes.string
};

export default ReferralNotes;
