/**
 * DashboardCardLabel.js
 *
 * Renders out the label for a dasboard card
 */
import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";

const styles = {
  label: {
    color: "#999",
    fontSize: "1.2em",
    fontWeight: 300,
    whiteSpace: "pre-wrap",
    lineHeight: 1.2
  }
};

const DashboardCardLabel = ({ classes, label }) => (
  <Typography className={classes.label}>{label}</Typography>
);

DashboardCardLabel.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string
};

export default withStyles(styles)(DashboardCardLabel);
