import React from "react";
import ReferralHandler from "services/referrals/handlers/ReferralHandler";
import Referral from "services/referrals/views/components/Referral";

const ReferralPage = props => (
  <div>
    <ReferralHandler referralId={props.match.params.referralId}>
      <Referral />
    </ReferralHandler>
  </div>
);

export default ReferralPage;
