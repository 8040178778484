import { Http } from "api/Http";

const Api = new Http("patients/1/");

class VitalsApi {
  constructor(api) {
    this.api = api;
  }

  getVitals = async patientId => {
    let res = await this.api.get("patient/", patientId + "/vitals");
    return res.data || [];
  };

  getVitalsDetails = async vitalsId => {
    let res = await this.api.get("vitals/", vitalsId);
    return res.data || [];
  };

  postVitals = async (patientId, vitals) => {
    let res = await this.api.post("patient/" + patientId + "/vitals", vitals);
    return res.data || {};
  };
}

const newApi = new VitalsApi(Api);
export default newApi;
