/**
 * AppointmentsHandler.js
 *
 * Attaches appointment state to a child component to render appointments on the dashboard
 */
import React from "react";
import PropTypes from "prop-types";
import useAppointments from "../state/useAppointments";

const AppointmentsHandler = ({ children }) => {
  let state = useAppointments();
  return React.cloneElement(children, state);
};

AppointmentsHandler.propTypes = {
  children: PropTypes.node
};

export default AppointmentsHandler;
