/**
 * Component for new charge diagnosis and procedures
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import AddIconBox from "./AddIconBox";

const styles = {
  card: {
    position: "relative",
    marginBottom: 12
  }
};

const Cards = ({ label, addIconOnClick, disabled, content, classes }) => (
  <Card className={classes.card}>
    <AddIconBox
      label={label}
      addIconOnClick={addIconOnClick}
      disabled={disabled}
    />
    {content}
  </Card>
);

Cards.propTypes = {
  label: PropTypes.string,
  addIconOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  content: PropTypes.object
};

export default withStyles(styles)(Cards);
