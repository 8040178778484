import React from "react";
import LogoutAlertHandler from "../handlers/LogoutAlertHandler";
import LogoutAlertDialog from "./components/LogoutAlertDialog";

const RenderLogoutAlert = () => (
  <LogoutAlertHandler>
    <LogoutAlertDialog />
  </LogoutAlertHandler>
);

export default RenderLogoutAlert;
