import { Http } from "api/Http";

const Api = new Http("references/1");

class PostChargesSearchApi {
  constructor(api) {
    this.api = api;
  }

  diagnosisSearch = async keyword => {
    let url = "/diagnosis/?search=" + encodeURIComponent(keyword);
    let res = await this.api.get(url);
    return res.data || [];
  };

  procedureCodeSearch = async (keyword, feeScheduleId) => {
    let url = "/fee/?search=" + encodeURIComponent(keyword) + "&feeScheduleId=" + encodeURIComponent(feeScheduleId);
    let res = await this.api.get(url);
    return res.data || [];
  };
}

const newApi = new PostChargesSearchApi(Api);
export default newApi;
