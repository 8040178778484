import React, { Component } from "react";
import { Theme } from "@material-ui/core/styles";
import backgroundImage from "images/MPMobileBackground.png";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { RouteComponentProps, withRouter } from "react-router";
import LoginStore from "services/login/Store";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) => {
  // breakpoint for styles applicable when the screen can no longer fit all 3 buttons horizontally.
  const buttonBreakpoint = theme.breakpoints.down(420);
  return createStyles({
    loadingBackground: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: theme.spacing(2.5),
      paddingTop: 0,
      paddingBottom: 0,
      backgroundImage: `url(${backgroundImage})`,
      backgroundDimension: "contain"
    },
    messageContainer: {
      overflowY: "auto",
      overflowX: "hidden",
      display: "flex",
      flexDirection: "column"
    },
    message: {
      whiteSpace: "pre-wrap"
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignSelf: "center",
      [buttonBreakpoint]: {
        flexDirection: "column",
        width: "100%"
      }
    },
    button: {
      margin: theme.spacing(2)
    }
  });
};

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  children: JSX.Element | JSX.Element[];
}

interface State {
  error?: unknown;
}

class _ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  navigateHome = () => {
    this.setState(prev => ({ ...prev, error: undefined }));
    this.props.history.push("/");
  };

  reload = () => {
    this.setState(prev => ({ ...prev, error: undefined }));
    window.location.reload();
  };

  logOut = () => {
    this.setState(prev => ({ ...prev, error: undefined }));
    LoginStore.logout();
  };

  render(): JSX.Element | JSX.Element[] {
    const error = this.state.error;
    if (error === undefined) {
      return this.props.children;
    }

    let message: string;
    if (error instanceof Error) {
      message = `${error.name}: ${error.message}`;
    } else {
      message = `Unknown error type: ${JSON.stringify(error)}`;
    }

    return (
      <div className={this.props.classes.loadingBackground}>
        <div className={this.props.classes.messageContainer}>
          <h2>An Error Occured</h2>
          <Typography variant="body1" className={this.props.classes.message}>
            {message}
          </Typography>
          <div className={this.props.classes.buttonContainer}>
            <Button
              className={this.props.classes.button}
              variant="contained"
              type="button"
              color="primary"
              onClick={this.navigateHome}
            >
              Go Home
            </Button>
            <Button
              className={this.props.classes.button}
              variant="contained"
              type="button"
              color="primary"
              onClick={this.reload}
            >
              Reload
            </Button>
            <Button
              className={this.props.classes.button}
              variant="contained"
              type="button"
              color="primary"
              onClick={this.logOut}
            >
              Log Out
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export const ErrorBoundary = withRouter(withStyles(styles)(_ErrorBoundary));
