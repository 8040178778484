import React from "react";
import UISearch from "components/ui/UISearch";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const SearchFormInput = ({ data, actions }) => (
  <div>
    <div className="sub-toolbar">
      <UISearch
        keyword={data.diagnosisSearchKeyword}
        parentOnChange={actions.handleChange}
        parentOnSubmit={actions.handleSearch}
        placeholder={"Search for a diagnosis..."}
      />
    </div>
  </div>
);

SearchFormInput.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default observer(SearchFormInput);
