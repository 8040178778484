import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AlertDialog from "./components/AlertDialog";
import AlertsBarAlerts from "./components/AlertsBarAlerts";

const styles = {
  root: {
    position: "relative",
    paddingTop: 6,
    paddingBottom: 5,
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.5)"
  }
};

const AlertsBar = ({ classes }) => (
  <div className={classes.root}>
    <AlertDialog />
    <AlertsBarAlerts />
  </div>
);

AlertsBar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(AlertsBar);
