/**
 * WelcomeUser.js
 *
 * Renders greeting for the logged in user
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LoggedInUser from "components/LoggedInUser";

const styles = {
  title: {
    color: "#000",
    fontSize: "2.5em",
    fontWeight: 100,
    lineHeight: 1,
    marginBottom: 20
  }
};

const WelcomeUser = ({ classes }) => (
  <Typography className={classes.title} variant="h6">
    Welcome, <LoggedInUser fullName />!
  </Typography>
);

WelcomeUser.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(WelcomeUser);
