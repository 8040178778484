/**
 * SearchProvider.js
 *
 * Handles global state of referral search results between routes using a state handler
 */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import useSearch from "./useSearch";

export const SearchResults = createContext();
export function SearchResultsProvider({ children }) {
  let state = useSearch();
  return (
    <SearchResults.Provider value={state}>{children}</SearchResults.Provider>
  );
}

SearchResultsProvider.propTypes = {
  children: PropTypes.node
};
