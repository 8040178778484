import React from "react";
import { observer } from "mobx-react";
import { withStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UIDialog from "components/ui/UIDialog";
import ApptFiltersForm from "./ApptFiltersForm";
import UIStore from "../store/UIStore";
import ApptStore from "services/appointments/store/DataStore";
import PropTypes from "prop-types";
import { StoreContext } from "utils/useStore";

const styles = theme => ({
  apply: {
    color: theme.palette.common.white,
    background: theme.palette.common.buttonGradient + " !important;",
    width: "90%"
  }
});

class ApptFiltersDialog extends React.Component {
  static contextType = StoreContext;

  closeDialog = () => {
    this.uiStore.toggleFiltersDialog();
  };

  get uiStore() {
    return this.context.getStore(UIStore);
  }

  saveChanges = () => {
    //This method will submit the filter form changes.
    this.formRef.submit();
    //It will refetch the appts
    this.context.getStore(ApptStore).reloadAppts(this.context);
    this.closeDialog();
  };

  rightNav = () => {
    return <CloseIcon onClick={this.closeDialog} color="inherit" />;
  };

  filterForm = () => {
    return (
      <ApptFiltersForm
        onRef={ref => {
          this.formRef = ref;
        }}
      />
    );
  };

  actions = () => {
    let { classes } = this.props;
    return (
      <Button
        variant="contained"
        className={classes.apply}
        onClick={this.saveChanges}
      >
        Apply Filters
      </Button>
    );
  };

  render() {
    let open = this.uiStore.showFiltersDialog;
    return (
      <UIDialog
        open={open}
        title="Filter Appointments"
        rightNav={this.rightNav}
        content={this.filterForm}
        actions={this.actions}
      />
    );
  }
}

ApptFiltersDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(observer(ApptFiltersDialog));
