/**
 * useLoginDialog.js
 *
 * Handles the state for the login dialog visibility
 */
import { useState, useEffect } from "react";
import LoginStore from "../Store";

const useLoginDialog = () => {
  let [open, setOpen] = useState(false);

  useEffect(() => {
    // login is open so destroy the jwt
    if (open === true) LoginStore.destroy();

    // watch for changes on the open state
  }, [open]);

  // opens the login dialog
  let show = () => setOpen(true);
  let hide = () => setOpen(false);

  return {
    open,
    show,
    hide
  };
};

export default useLoginDialog;
