import React from "react";
import PreferenceList from "./components/PreferenceList";
import PropTypes from "prop-types";

const PreferencesApp = ({ appStore }) => {
  appStore.setTitle("Preferences");
  appStore.setRightNav();

  return <PreferenceList />;
};

PreferencesApp.propTypes = {
  appStore: PropTypes.object.isRequired
};

export default PreferencesApp;
