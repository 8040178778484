import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  pointers: {
    color: "black",

    // get MUI descendant errors if we use a div so make the span block
    // so the pointers appear on a new line
    display: "block"
  }
});

const Pointers = props => (
  <span className={props.classes.pointers}> Pointers: {props.pointer}</span>
);

export default withStyles(styles)(Pointers);
