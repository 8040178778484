import React from "react";
import PropTypes from "prop-types";

const SectionHeader = ({ field }) => {
  return <div>{field.label}</div>;
};

SectionHeader.propTypes = {
  field: PropTypes.object.isRequired
};

export default SectionHeader;
