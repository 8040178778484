import { flow, getSnapshot, types } from "mobx-state-tree";
import LoginStore from "services/login/Store";
import Login from "services/login/Store";
import { UserModel } from "services/users/Users";

export interface ApiError {
  response?: {
    status?: number;
  };
  message?: string;
}

const Store = types
  .model("AppStore", {
    toolbarTitle: "MacPractice",
    toolbarSubTitle: types.maybeNull(types.string),
    user: types.maybeNull(UserModel)
  })
  .volatile(() => ({
    rightNav: null,
    // This should eventually be moved to the normal properties, but since we're assigning objects blindly from axios this is safer for now. If we guess at the structure of the error and are wrong, using the strongly typed properties will result in a runtime error instead of the user seeing an error dialog.
    apiError: null as ApiError | null
  }))
  .actions(self => ({
    setUser: flow(function*(usersStore) {
      if (!self.user) {
        const tokenData = LoginStore.decode();
        const user = yield usersStore.getUser(tokenData.userId);
        // The way things are set up, each store is it's own full state tree, so we can't use MST's identifiers and references.
        // Instead we create a brand new model from a snapshot.
        self.user = getSnapshot(user);
        return self.user;
      }
      return self.user;
    }),

    apiResponseError(error: any) {
      self.apiError = error;
    },

    setTitle(title: string, subTitle: string | null = null) {
      self.toolbarTitle = title;
      self.toolbarSubTitle = subTitle;
    },

    setSubTitle(subTitle: string | null) {
      self.toolbarSubTitle = subTitle;
    },

    setRightNav(rightNav: any) {
      self.rightNav = rightNav;
    },

    dismissError: () => {
      self.apiError = null;
    }
  }))
  .views(self => ({
    /**
     * Pulling the userId from decoded JWT if self.user
     * does not exist so we don't need to implement a promise
     * everytime we need the userId for preferences
     *
     */
    get userId(): number {
      if (!self.user) {
        const decodedJwt = Login.decode();
        return decodedJwt.userId;
      }
      return self.user.userId;
    },

    /**
     * @return Logged in user's first name
     */
    get userName(): string {
      return self.user ? self.user.firstName : "";
    },

    /**
     * @return Logged in user's first name
     */
    get userFullName(): string {
      return self.user ? self.user.firstName + " " + self.user.lastName : "";
    }
  }));

export default Store;
