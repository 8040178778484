import React from "react";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import UIToolbar from "components/ui/UIToolbar";
import MainDrawer from "components/MainDrawer";
import PropTypes from "prop-types";

const styles = theme => ({
  appBar: {
    textAlign: "center",
    fontWeight: "light",
    position: "fixed",
    width: "100%",
    top: 0,
    boxShadow: "none",
    marginLeft: theme.spacing(30),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.spacing(30)}px)`
    }
  }
});

class UIAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolbarTitle: props.store.toolbarTitle,
      mobileOpen: false,
      logout: false
    };
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  // ----------------------------------------------------

  render() {
    let { store, classes } = this.props;
    let { mobileOpen } = this.state;
    let {
      toolbarTitle,
      toolbarSubTitle,
      rightNav,
      editButtonComponent
    } = store;

    /*
    // we need to refresh the app when logging out to clear out persisted data
    if (logout === true) {
      return <Redirect to="/login" />;
    }
    */

    return (
      <React.Fragment>
        <AppBar className={classes.appBar}>
          <UIToolbar
            handleDrawerToggle={this.handleDrawerToggle}
            rightNav={rightNav}
            title={toolbarTitle}
            subTitle={toolbarSubTitle}
            editButtonComponent={editButtonComponent}
          />
        </AppBar>

        <MainDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
        />
      </React.Fragment>
    );
  }
}

UIAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default withStyles(styles)(observer(UIAppBar));
