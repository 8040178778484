/**
 * LoginProvider.js
 *
 * Provides state for the autologout timer, alert and login dialog child components
 */
import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import Store from "../Store";
import useLogin from "./useLogin";
import useLogoutAlertTimer from "./useLogoutAlertTimer";
import useAlertCounter from "./useAlertCounter";
import useLoginDialog from "./useLoginDialog";
import Preferences from "services/preferences/Store";
import { useStore } from "utils/useStore";

// creates state context
export const LoginContext = createContext();

// component to wrap child components in to share the context state
export function LoginProvider({ children }) {
  const preferencesStore = useStore(Preferences);
  // intantiate state
  let login = useLogin();

  // need to call Store.logoutMinutes on each request in the event the preference got changed
  let timer = useLogoutAlertTimer(Store.logoutMinutes(preferencesStore));

  let countdown = useAlertCounter();
  let dialog = useLoginDialog();

  // create state object
  let state = useMemo(() => {
    return {
      login,
      timer,
      countdown,
      dialog
    };
  }, [login, timer, countdown, dialog]);

  return (
    <LoginContext.Provider value={state}>{children}</LoginContext.Provider>
  );
}

LoginProvider.propTypes = {
  children: PropTypes.node
};
