/**
 * Wrapper for Material UI Dialog
 *
 * @url https://material-ui-next.com/demos/dialogs/
 */

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";

import UIDialogToolbar from "./UIDialogToolbar";

// -----------------------------------------------

/**
 * @param isMobile bool Detects mobile device
 * @param open bool Show/hide dialog
 * @param title string Dialog toolbar title
 * @param rightNav function Content for the dialog
 */

const UIDialog = ({
  open,
  title,
  rightNav,
  content,
  actions,
  fullScreen,
  noPadding = false
}) => {
  let getRightNav = rightNav ? rightNav() : null;
  let getContent = content ? content() : null;
  let getActions = actions ? actions() : null;
  let classNames = ["uiDialogContent"];
  if (noPadding) classNames = [...classNames, "noPadding"].join(" ");

  // -----------------------------------

  return (
    <Dialog fullScreen={fullScreen || isMobile} open={open || false} fullWidth>
      {(title != null || getRightNav != null) && (
        <UIDialogToolbar title={title} rightNav={() => getRightNav} />
      )}

      <div className={classNames}>{getContent}</div>
      {actions && <div className="actionContainer">{getActions}</div>}
    </Dialog>
  );
};

UIDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  rightNav: PropTypes.func,
  content: PropTypes.func,
  actions: PropTypes.func,
  fullScreen: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default UIDialog;
