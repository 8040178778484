/**
 * SearchHandler.js
 *
 * Returns a cloned child component with props set in the search results provider
 *
 * Persists the search results between route changes in the referrals app
 */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SearchResults } from "../state/SearchResultsProvider";

const SearchHandler = ({ children }) => {
  let childProps = useContext(SearchResults);
  return React.cloneElement(children, childProps);
};

SearchHandler.propTypes = {
  children: PropTypes.node
};

export default SearchHandler;
