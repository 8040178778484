import React from "react";
import LoginDialogHandler from "../handlers/LoginDialogHandler";
import LoginDialog from "./components/LoginDialog";

const RenderLoginDialog = () => (
  <LoginDialogHandler>
    <LoginDialog />
  </LoginDialogHandler>
);

export default RenderLoginDialog;
