import { Http } from "api/Http";

const Api = new Http("patients/1/patient");

class PatientsApi {
  constructor(api) {
    this.api = api;
  }

  postCharge = async (patientId, postChargeModel) => {
    let res = await this.api.post("/" + patientId + "/charge", postChargeModel);
    return res.status || {};
  };
}

const newApi = new PatientsApi(Api);
export default newApi;
