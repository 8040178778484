/**
 * AlertListItems.js
 *
 * Maps over a list of alerts
 */
import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  listItemText: {
    padding: 0
  },
  dateString: {
    textAlign: "right",
    padding: 0
  }
};

const AlertListItems = ({ alerts, classes }) => (
  <List>
    {alerts &&
      alerts.map((a, idx) => (
        <ListItem key={idx}>
          <ListItemText
            primary={a.description}
            className={classes.listItemText}
          />
          <ListItemText
            secondary={a.dateString}
            className={classes.dateString}
          />
        </ListItem>
      ))}
  </List>
);

AlertListItems.propTypes = {
  alerts: PropTypes.array,
  classes: PropTypes.object
};

export default withStyles(styles)(React.memo(AlertListItems));
