import React from "react";

const UIPhoneLink = ({ phone }) => {
  const link = "tel:" + phone;
  return (
    <a className="external" href={link}>
      {phone}
    </a>
  );
};

export default UIPhoneLink;
