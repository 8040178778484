import { flow, types } from "mobx-state-tree";
import Api, { PatientSearchModel } from "../Api";

const Store = types
  .model("PatientSearchStore", {
    patients: types.array(PatientSearchModel),
    loading: false,
    keyword: "",
    /** Used to show/hide no results found in ui */
    searched: false
  })
  .actions(self => ({
    search: flow(function*(keyword) {
      self.loading = true;

      let patients = yield Api.search(keyword);
      self.loading = false;
      self.searched = true;
      self.set(patients);
    }),

    find: flow(function*(patientId) {
      self.loading = true;

      let patient = yield Api.find(patientId);
      self.loading = false;
      return patient;
    }),

    set: patients => {
      self.patients = patients;
    },

    setKeyword: keyword => {
      self.keyword = keyword;
    },

    clearSearch: () => {
      self.keyword = "";
      self.searched = false;
      self.set([]);
    }
  }))
  .views(self => ({
    get sortedPatients() {
      return self.patients.slice().sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
    }
  }));

export default Store;
