const ApptFilterFields = [
  {
    name: "resources",
    label: "Resources",
    hasCheckAll: true,
    type: "checkboxgroup",
    queryKey: "resources",
    options: []
  },
  {
    name: "providers",
    label: "Providers",
    hasCheckAll: true,
    type: "checkboxgroup",
    queryKey: "providers",
    options: []
  },
  {
    name: "order",
    label: "Appointment Order",
    type: "radiogroup",
    value: "1",
    queryKey: "sortOrder",
    options: [
      { id: "1", name: "First to Last" },
      { id: "0", name: "Last to First" }
    ]
  }
];

export default ApptFilterFields;
