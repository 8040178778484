import React from "react";
import Typography from "@material-ui/core/Typography";
import UITextHeader from "components/ui/UITextHeader";

const HelpDesk = () => (
  <React.Fragment>
    <UITextHeader title="HelpDesk" />
    <Typography className="padding">
      <a href="https://helpdesk.macpractice.net">
        <b>Visit our HelpDesk</b>
      </a>{" "}
      for tutorials, videos, online class information, webinars, training and
      more.
    </Typography>
  </React.Fragment>
);

export default HelpDesk;
