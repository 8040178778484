/**
 * ReferralPanels.js
 *
 * Renders panels with referral information
 */
import React from "react";
import PropTypes from "prop-types";
import UIPanelGroup from "components/ui/UIPanelGroup";
import ReferralContactInformation from "./ReferralContactInformation";
import ReferralNotes from "./ReferralNotes";

const ReferralPanels = ({ referral }) => {
  const ContactInfo = () => <ReferralContactInformation referral={referral} />;
  const Notes = () => <ReferralNotes notes={referral.notes} />;

  let panels = () => {
    return [
      {
        title: "Contact Information",
        content: ContactInfo
      },
      {
        title: "Referrer Notes",
        content: Notes
      }
    ];
  };

  return <UIPanelGroup panels={panels()} />;
};

ReferralPanels.propTypes = {
  referral: PropTypes.object
};

export default ReferralPanels;
