import React from "react";
import Api from "../InsuranceAndGuarantorsAPI";
import UIProgress from "components/ui/UIProgress";
import AppRouter from "components/Router";
import GuarantorDetails from "./GuarantorDetails";
import GuarantorsLinks from "./GuarantorsLinks";
import PropTypes from "prop-types";

const appRoutes = [
  {
    path: "*/guarantors",
    exact: true,
    component: GuarantorsLinks
  },
  {
    path: "*/guarantors/:guarantorType",
    exact: true,
    component: GuarantorDetails
  }
];

class Guarantors extends React.Component {
  state = {
    loading: true,
    primary: {},
    secondary: {}
  };

  componentDidMount() {
    this.fetchData();
  }

  dateFromString = dateString => {
    return dateString ? new Date(dateString) : "";
  };

  fetchData() {
    let patientId = Number(this.props.match.params.patientId);
    Api.getGuarantors(patientId).then(response => {
      if (response && response.primary) {
        response.primary.birthdate = this.dateFromString(
          response.primary.birthdate
        );
      }

      if (response && response.secondary) {
        response.secondary.birthdate = this.dateFromString(
          response.secondary.birthdate
        );
      }

      this.setState({
        primary: response.primary,
        secondary: response.secondary,
        loading: false
      });
    });
  }

  render() {
    let { primary, secondary, loading } = this.state;
    if (loading) return <UIProgress />;
    else
      return (
        <AppRouter
          routes={appRoutes}
          primaryGuarantor={primary}
          secondaryGuarantor={secondary}
        />
      );
  }
}

Guarantors.propTypes = {
  match: PropTypes.object
};

export default Guarantors;
