/**
 * HTTP Request wrapper called from API's.
 *
 * The axios library is not used anywhere else in the application and if
 * we need to change http libraries, it can be done here in one spot
 *
 * This class is instantiated in the Api.js files
 *
 * @see config.js For request/response/error handling
 * @see https://github.com/axios/axios
 */
import axios from "axios";
import { Config } from "./config";

export class Http {
  /**
   * @param {String} resource | Url resource, including version if applicable after the base url
   */
  constructor(resource) {
    this.baseUrl = Config.apiBaseUrl + resource;
  }

  get = (url, params = "") => axios.get(this.baseUrl + url + params);
  post = (url, data) => axios.post(this.baseUrl + url, data);
  put = (url, data) => axios.put(this.baseUrl + url, data);
  patch = (url, data) => axios.patch(this.baseUrl + url, data);
  delete = url => axios.delete(this.baseUrl + url);
}
