import { flow, types } from "mobx-state-tree";
import { format } from "date-fns";
import { addFilterValueToParams, FilterValue } from "./FilterValue";
import ui from "services/appointments/store/UIStore";
import ApptFormOptions from "services/appointments/store/ApptFormOptions";

/**
 * An MST type representing an appointment filters preference.
 */
export const AppointmentFilters = types
  .model("AppointmentFilters", {
    order: types.number,
    resources: FilterValue,
    providers: FilterValue
  })
  .views(self => ({
    getUrl(storeMananger) {
      const uiStore = storeMananger.getStore(ui);
      const queryParameters = new URLSearchParams();
      const filterDateString = format(uiStore.date, "yyyy-MM-dd");
      queryParameters.append("date", filterDateString);
      addFilterValueToParams(self.providers, "providers", queryParameters);
      addFilterValueToParams(self.resources, "resources", queryParameters);

      if (self.order === null || self.order === undefined) {
        queryParameters.append("sortAscending", self.order);
      }

      return "/?" + queryParameters.toString();
    },

    getTransparenciesUrl: flow(function*(storeManager) {
      const uiStore = storeManager.getStore(ui);
      let resources =
        (yield storeManager.getStore(ApptFormOptions).getResources()) || [];
      if (resources.length === 0) return null;
      const queryParameters = new URLSearchParams();
      const filterDateString = format(uiStore.date, "yyyy-MM-dd");
      queryParameters.append("date", filterDateString);
      if (self.resources === "all") {
        let value = resources.map(r => {
          return r.resourceId;
        });
        queryParameters.append("resourceIds", value);
      } else {
        addFilterValueToParams(self.resources, "resourceIds", queryParameters);
      }
      return "/?" + queryParameters.toString();
    })
  }));
