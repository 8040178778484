import { Http } from "api/Http";

const Api = new Http("users/1");

class UsersApi {
  constructor(api) {
    this.api = api;
  }

  fetchUsers = async () => {
    let res = await this.api.get("/users");
    let users = res.data || [];

    return users.filter(user => user.userId > 1);
  };

  fetchProviders = async () => {
    let res = await this.api.get("/providers");
    return res.data || [];
  };
}

const newApi = new UsersApi(Api);
export default newApi;
