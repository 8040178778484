import OrdersPage from "services/orders/pages/OrdersPage";
import OrderPage from "services/orders/pages/OrderPage";

const Routes = [
  {
    path: "/orders",
    component: OrdersPage,
    exact: true
  },
  {
    path: "/orders/:orderId",
    component: OrderPage,
    exact: true
  }
];

export default Routes;
