import { flow, types } from "mobx-state-tree";
import { PreferenceBase } from "./PreferenceBase";

export const StringPreference = types
  .compose(
    "StringPreference",
    PreferenceBase,
    types.model("_StringPreference", {
      value: types.maybe(types.string)
    })
  )
  .actions(self => ({
    /**
     * Returns the value that should be sent to the server representing this preferences update. This method does not update the preference with the new value.
     * @param key The database pkey this preference represents.
     * @param value The new value of the preference.
     * @returns The value representing this update to be sent to the server.
     */
    valueForUpdate(key: string, value: string) {
      return value;
    },
    /**
     * Applies a loaded preference value from the database to this preference object. If the value needs to have some work done to it before storing it (e.g. fetching models from IDs) that happens in this method.
     * @param value The new value from the server.
     */
    // disable lint to keep the interface the same
    // eslint-disable-next-line require-yield
    applyLoadedPrefValue: flow(function*(value) {
      self.value = value;
    })
  }));
