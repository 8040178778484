import { Http } from "api/Http";

const Api = new Http("patients/1/patient");

class ProviderOfficeFeeApi {
  constructor(api) {
    this.api = api;
  }

  getProviderOfficeFee = async patientId => {
    let res = await this.api.get("/" + patientId + "/provider");
    return res.data || {};
  };

  updateProviderOfficeFee = async (patientId, providerOfficeFeeIds) => {
    await this.api.patch("/" + patientId + "/provider", providerOfficeFeeIds);
    return {};
  };
}

const newApi = new ProviderOfficeFeeApi(Api);
export default newApi;
