import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: 8
  }
});

function UICustomDateRange(props) {
  let onChange = event => {
    let fieldName = event.target.name;
    let newDate = event.target.value;
    props.dateOnChange(fieldName, newDate);
  };

  const { classes, startDate, endDate } = props;

  return (
    <form className={classes.container} noValidate>
      <TextField
        label="Start Date"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        onChange={onChange}
        name={"start"}
        value={startDate}
      />

      <TextField
        label="End Date"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        onChange={onChange}
        name={"end"}
        value={endDate}
      />
    </form>
  );
}

export default withStyles(styles)(UICustomDateRange);
