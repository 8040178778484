/**
 * Maps data to create a new array for form select inputs by creating an object
 * of id and name
 *
 * @since 7/10/2018
 * @param {Array} data Array of data create new array
 * @param {String} id String of the value for the select input
 * @param {String} name Option label for select input
 * @return {{id: string, name: string}[]}
 */
const DataForFormSelect = <
  T extends Record<I, { toString(): string }> & Record<N, string>,
  I extends keyof T,
  N extends keyof T
>(
  data: T[],
  id: I,
  name: N
) => {
  if (Array.isArray(data)) {
    const newData: { id: string; name: string }[] = [];
    data.map(d => newData.push({ id: d[id].toString(), name: d[name] }));
    return newData;
  }
  // data was not an array
  console.log("Api data was not an array: " + id + " / " + name);
  return [];
};

export default DataForFormSelect;
