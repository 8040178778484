import React from "react";
import Router from "components/Router";
import Routes from "./Routes";
import PropTypes from "prop-types";

class ApptsApp extends React.Component {
  constructor(props) {
    super(props);
    this.props.appStore.setTitle("Schedule");
    this.props.appStore.setRightNav(null);
  }

  render() {
    return <Router routes={Routes} />;
  }
}

export default ApptsApp;

ApptsApp.propTypes = {
  appStore: PropTypes.object.isRequired
};
