import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 1102,
    padding: 0
  },
  speedDialIcon: {
    color: theme.palette.common.white
  },
  speedDialActionIcon: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  }
}));

const UISpeedDial = props => {
  const classes = useStyles();
  let [open, setOpen] = useState(false);
  let speedDialCSS = classes.speedDial;
  if (props.sdClassName) {
    speedDialCSS = [classes.speedDial, props.sdClassName].join(" ");
  }

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={speedDialCSS}
        icon={<SpeedDialIcon className={classes.speedDialIcon} />}
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={isMobile ? undefined : handleClose}
        open={open}
      >
        {props.actions.map(action => (
          <SpeedDialAction
            className={classes.speedDialActionIcon}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.handleClick}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

UISpeedDial.propTypes = {
  actions: PropTypes.array,
  sdClassName: PropTypes.string
};

export default UISpeedDial;
