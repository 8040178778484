import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.common.text,
    fontStyle: "italic",
    textAlign: "center"
  }
});

const UINotFound = ({ message, classes }) => {
  return <Typography className={classes.root}>{message}</Typography>;
};

UINotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};

export default withStyles(styles)(UINotFound);
