import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

const AutoLogoutDialog = ({ open, count, onClick }) => (
  <Dialog open={open}>
    <DialogTitle>Warning</DialogTitle>

    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Due to lack of activity, this app will log out in {count} seconds.
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClick} color="primary" fullWidth autoFocus>
        Stay Logged In
      </Button>
    </DialogActions>
  </Dialog>
);

AutoLogoutDialog.propTypes = {
  open: PropTypes.bool,
  count: PropTypes.number,
  onClick: PropTypes.func
};

export default AutoLogoutDialog;
