import { SnapshotIn, types } from "mobx-state-tree";

/**
 * A MST type representing a filter group's value. Can either represent manual selection of some items, or `"all"` for when all of the items are selected
 */
export const FilterValue = types.union(
  types.literal("all"),
  types.array(types.number)
);

/**
 * Parses a `FilterValue` from the server response representation.
 * @param {*} value The server response for the filter group.
 * @returns A valid `FilterValue`, or undefined if the response was not correct.
 */
export const parseFilterValue = (value: any) => {
  if ("options" in value) {
    return FilterValue.create(value.options);
  } else if ("all" in value && value.all === true) {
    return FilterValue.create("all");
  } else {
    return undefined;
  }
};

/**
 * Creates a value representing the `FilterValue` suitable for sending back to the server
 * @returns A valid server update object.
 */
export const filterValueToUpdate = (filter: SnapshotIn<typeof FilterValue>) => {
  if (filter === "all") {
    return { all: true };
  } else {
    return { options: filter };
  }
};

/**
 * A convenience function for adding parameters to `URLSearchParams` unless the value is null or undefined.
 * @param {number[] | 'all' | null | undefined} value The value to conditionally add to the query parameters
 * @param {string} parameterName The name to use for the query parameter
 * @param {URLSearchParams} queryParameters The search parameters object
 */
export function addFilterValueToParams(
  value: number[] | "all" | null | undefined,
  parameterName: string,
  queryParameters: URLSearchParams
) {
  let param: number[] | string | "all" | null | undefined = value;
  if (param !== "all" && param !== undefined && param !== null) {
    if (Array.isArray(value)) {
      param = value.join(",");
    }
    queryParameters.append(parameterName, param.toString());
  }
}
