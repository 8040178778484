import { flow, types } from "mobx-state-tree";
import Api from "./PostChargesSearchApi";

export const DiagnosisModel = types.model("DiagnosisModel", {
  code: types.string,
  diagnosis: types.string,
  umlsId: types.maybe(types.number),
  pointer: types.maybe(types.string),
  index: types.maybe(types.number)
});

export const ProcedureCodeFeeModel = types.model("ProcedureCodeFeeModel", {
  code: types.string,
  description: types.string,
  notes: types.maybe(types.string),
  fee: types.number,
  units: types.number,
  calculationMethod: types.number
});

const Store = types
  .model("PostChargesSearchStore", {
    diagnosesSearchResult: types.array(DiagnosisModel),
    diagnosisSearchKeyword: "",
    procedureCodes: types.array(ProcedureCodeFeeModel),
    loading: false,
    procedureCodeSearchKeyword: "",
    searched: false,
    feeScheduleId: types.maybe(types.number)
  })
  .actions(self => ({
    searchDiagnoses: flow(function*() {
      self.loading = true;
      let res = yield Api.diagnosisSearch(self.diagnosisSearchKeyword);
      self.diagnosisSearchKeyword = "";
      self.loading = false;
      self.searched = true;
      self.setDiagnosesSearchResult(res);
    }),

    searchProcedureCode: flow(function*() {
      self.loading = true;

      let procedureCodeSearchResult = yield Api.procedureCodeSearch(
        self.procedureCodeSearchKeyword,
        self.feeScheduleId
      );
      self.loading = false;
      self.searched = true;
      self.setProcedureCodes(procedureCodeSearchResult);
    }),

    setDiagnosesSearchResult: diagnosesSearchResult => {
      self.diagnosesSearchResult = diagnosesSearchResult;
    },

    setProcedureCodes: procedureCodes => {
      self.procedureCodes = procedureCodes;
    },

    setDiagnosisKeyword: keyword => {
      self.diagnosisSearchKeyword = keyword;
    },

    setProcedureCodeKeyword: keyword => {
      self.procedureCodeSearchKeyword = keyword;
    },

    setFeeScheduleId: feeScheduleId => {
      self.feeScheduleId = feeScheduleId;
    },

    clearDiagnosisSearch: () => {
      self.diagnosisSearchKeyword = "";
      self.searched = false;
      self.setDiagnosesSearchResult();
    },

    clearProcedureCodeSearch: () => {
      self.procedureCodeSearchKeyword = "";
      self.searched = false;
      self.setProcedureCodes([]);
    }
  }));

export default Store;
