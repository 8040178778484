/**
 * useSearch.js
 *
 * Mangages referral search state
 */
import { useState } from "react";
import Api from "../Api";

const defaultState = {
  searching: false,
  doneSearching: false,
  keyword: "",
  referrals: []
};

const useSearch = () => {
  let [state, setState] = useState(defaultState);

  let onChange = value => {
    // the clear icon has been clicked in the input so start over
    if (!value.trim()) {
      setState(defaultState);
    } else {
      setState({ ...state, keyword: value });
    }
  };

  // fetches the referrals by keyword from the api and updates the state
  let onSubmit = async () => {
    setState({ ...state, searching: true });
    let res = await Api.search(state.keyword);
    let referrals = handleReferrals(res);
    setState({
      ...state,
      searching: false,
      doneSearching: true,
      referrals: referrals
    });
  };

  /**
   * Adds additional props to the search results
   *
   * @param {Object} referrals
   * @return {Object}
   */
  let handleReferrals = referrals => {
    referrals.forEach(r => {
      r.linkTo = "/referrals/" + r.referralId;
      r.nameString = r.first + " " + r.last;
    });
    return referrals;
  };

  /**
   * Sort referral search results by first name
   *
   * @return {Array}
   */
  let sorted = () => {
    return state.referrals.sort((a, b) => a.first.localeCompare(b.first));
  };

  return {
    searching: state.search,
    doneSearching: state.doneSearching,
    keyword: state.keyword,
    referrals: sorted(),
    onChange,
    onSubmit
  };
};

export default useSearch;
