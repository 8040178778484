import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const UITextField = ({
  name,
  value,
  disabled,
  materialInputProps,
  placeholder,
  error,
  parentOnChange,
  parentOnBlur,
  label,
  htmlInputProps
}) => {
  let handleChange = event => {
    parentOnChange(event);
  };

  return (
    <TextField
      error={error}
      onChange={handleChange}
      onBlur={parentOnBlur}
      id={name}
      name={name}
      value={value}
      disabled={disabled}
      // eslint-disable-next-line
      InputProps={materialInputProps}
      // eslint-disable-next-line
      inputProps={htmlInputProps}
      placeholder={placeholder}
      variant="filled"
      label={label}
    />
  );
};

UITextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  materialInputProps: PropTypes.object,
  htmlInputProps: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  parentOnChange: PropTypes.func.isRequired,
  parentOnBlur: PropTypes.func,
  label: PropTypes.string
};

export default UITextField;
