/**
 * Referral.js
 *
 * Renders components for referral data
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import UIProgress from "components/ui/UIProgress";
import UINotFound from "components/ui/UINotFound";
import ReferralCard from "./ReferralCard";
import ReferralPanels from "./ReferralPanels";

const styles = {
  panels: {
    marginBottom: 20
  }
};

const Referral = ({ classes, loading, referral }) => (
  <div className="padding">
    {loading && <UIProgress />}
    {!loading && !referral && <UINotFound message="Referral not found" />}
    {referral && (
      <React.Fragment>
        <div className={classes.panels}>
          <ReferralCard referral={referral} />
        </div>
        <ReferralPanels referral={referral} />
      </React.Fragment>
    )}
  </div>
);

Referral.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  referral: PropTypes.object
};

export default withStyles(styles)(Referral);
