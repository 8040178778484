import React, { Component } from "react";
import Pointers from "../Pointers";

// default length of characters for the description to truncate
const DefaultTruncateLength = 40;

class ListItemHandler extends Component {
  /**
   * @var {Boolean} descriptionOpen | Toggle between truncated and full description
   */
  state = { descriptionOpen: false };

  // number of characters to truncate can be passed as prop or use default
  truncateLength = this.props.truncate || DefaultTruncateLength;

  componentDidMount() {
    // show the whole description if the prop says so
    // could be useful for a "show all" checkbox
    if (this.props.truncate === false) {
      this.toggleDescription();
    }
  }

  /**
   * @param {String} description
   * @return {String}
   */
  truncate(description) {
    if (description && description.length > this.truncateLength) {
      return description.substr(0, this.truncateLength) + "... ";
    }
    return description;
  }

  /**
   * Returns concatenated string of a truncated or full description along
   * with the pointers
   *
   * @param {String} description
   * @param {String} pointers
   * @return {String}
   */
  renderDescription() {
    let { diagnosis, pointer } = this.props.diagnosis;
    let { descriptionOpen } = this.state;
    let descriptionString =
      descriptionOpen === true ? diagnosis : this.truncate(diagnosis);
    return (
      <span>
        {descriptionString} <Pointers pointer={pointer} />
      </span>
    );
  }

  /**
   * Toogles state boolean
   */
  toggleDescription = () => {
    this.setState({ descriptionOpen: !this.state.descriptionOpen });
  };

  render() {
    return this.props.render(this.renderDescription(), this.toggleDescription);
  }
}

export default ListItemHandler;
