/**
 * RenderAppointments.js
 *
 * Wrapper to include the handler and ui component for dashboard appointments
 */
import React from "react";
import Appointments from "./components/Appointments";
import AppointmentsHandler from "../handlers/AppointmentsHandler";

const RenderAppointments = () => (
  <AppointmentsHandler>
    <Appointments />
  </AppointmentsHandler>
);

export default RenderAppointments;
