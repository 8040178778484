/**
 * Handles saving and loading errors displayed to the user from storage.
 */
const ErrorStore = {
  getError: () => localStorage.getItem("error"),
  setError: error => localStorage.setItem("error", error),
  removeError: () => localStorage.removeItem("error")
};

export default ErrorStore;
