import { types } from "mobx-state-tree";

const PhoneModel = types
  .model("PhoneModel", {
    number: types.maybe(types.string),
    type: types.maybe(types.number)
  })
  .actions(self => ({
    setType(type) {
      self.type = type;
    }
  }));

export default PhoneModel;
