/**
 * Api.js
 *
 * HTTP requests for referrals
 */
import { Http } from "api/Http";

// api to pass to new api class instance
const Api = new Http("referrer/1/");

class ReferralsApi {
  /**
   * @param {Callable} api
   */
  constructor(api) {
    this.api = api;
  }

  /**
   * call api to search for referrals
   *
   * @param {String} keyword
   * @return {Object}
   */
  search = async keyword => {
    let res = await this.api.get(
      "/referrers/?search=" + encodeURIComponent(keyword)
    );
    return res.data || [];
  };

  /**
   * call api to find referral by id
   *
   * @param {Numner} referralId
   * @return {Object}
   */
  find = async referralId => {
    let res = await this.api.get("/referrers/" + referralId);
    return res.data || {};
  };
}

const newApi = new ReferralsApi(Api);
export default newApi;
