import { createContext, useContext } from "react";
import PreferencesStore from "services/preferences/Store";
import {
  IAnyModelType,
  IModelType,
  Instance,
  ModelProperties
} from "mobx-state-tree";
import { AllPrefs } from "services/preferences/models/AllPrefs";

/**
 * A React Context containing a StoreManager. The App component is responsible for setting (and resetting) the StoreManager at appropriate times (for example on logout).
 */
export const StoreContext = createContext<StoreManager | undefined>(undefined);

export interface StoreEnv {
  storeManager: StoreManager;
}

/**
 * An interface for stores which want to perform some custom initialization or invalidation when a component attempts to fetch the store from context.
 */
export interface CustomInitStore<I> {
  /**
   * This function is used instead of `create` to create an instance of the store, and can be used to conditionally update the store. Since this function can create new instances it should always return a valid instance, even if the passed in instance was not modified.
   *
   * **Warning**: If you implement `_onGetStoreFromContext` for a store you _must_ create the store with the correct environment. The environment is passed as the second parameter to the function, and can be passed to `create` as is.
   * @param instance The existing instance, or `undefined` if one hasn't been created yet.
   * @param env The environment the store should be created with.
   */
  _onGetStoreFromContext?(instance: I | undefined, env: StoreEnv): I;
}

/**
 * A class for managing stores for an App instance. Functional react components should use `useStore` to access store's, but class based components will interact directly with this class instead.
 */
export class StoreManager {
  map: Map<string, { instance: unknown; store: IAnyModelType }>;
  constructor() {
    /** A map containing the MST store spec, and an instance of the store spec. The map is keyed by the name of the store spec. The spec is retained only to perform sanity checks in `getStore`*/
    this.map = new Map();
  }

  /**
   * Gets an existing store instance if one exists, otherwise one is created.
   *
   * The way most of the Stores have been used, it would technically make the most sense to never create new stores so MobX could ensure views update to reflect new state. However many of the old Factories were creating new instances under some conditions, and so other code relies on MobX _not_ updating those models with new data.
   *
   * @param store The MobX State Tree model spec.
   * @returns An instance of the store
   */
  getStore<P extends ModelProperties, O, C, S>(
    store: IModelType<P, O, C, S> &
      CustomInitStore<Instance<IModelType<P, O, C, S>>>
  ): Instance<IModelType<P, O, C, S>> {
    // Environment to set on every store. This can be accessed from inside an MST model via `getEnv`.
    // Currently this is only used to work around some stores needing to reference other stores which were previously referencing a global variable.
    const env = this.newEnv();
    if (this.map.has(store.name)) {
      const found = this.map.get(store.name);
      if (found?.store !== store) {
        // A store was found with the same name, but references a different object. This shouldn't be possible unless either a name is reused accidentally.
        throw Error(`Duplicate store name detected: ${store.name}`);
      }

      // At this point we know the store in the map is the same as the one that was passed in, so we can use the types from the parameter to cast the store and instance to the correct types safely.
      const foundStore = found.store as typeof store;
      let foundInstance = found.instance as Instance<IModelType<P, O, C, S>>;
      if (foundStore._onGetStoreFromContext) {
        foundInstance = foundStore._onGetStoreFromContext(foundInstance, env);
        found.instance = foundInstance;
      }

      return foundInstance;
    } else {
      console.log(`Creating store ${store.name}`);

      let instance;
      if (store._onGetStoreFromContext !== undefined) {
        instance = store._onGetStoreFromContext(undefined, env);
      } else {
        instance = store.create(undefined, env);
      }

      const newStore = { instance, store };
      this.map.set(store.name, newStore);
      return newStore.instance;
    }
  }

  /**
   * Get a preference from the preference store. This is a shortcut for `getStore(PreferencesStore).get(key)`.
   *
   * Eventually PreferencesStore should be strongly typed so we can return a known type here.
   *
   * @param key The key to lookup a preference for.
   * @returns The located preference, or undefined if none was found.
   */
  getPreference<K extends keyof Instance<typeof AllPrefs>>(
    key: K
  ): Instance<typeof AllPrefs>[K]["value"] {
    return this.getStore(PreferencesStore).get(key);
  }

  /**
   * Creates a new environment object to be passed as the second argument to an MST `create` call.
   * Most stores shouldn't need to manually pass the environment object, but some areas use MST models when editing a new object. Since the models are placed in the MST "tree" immediately they don't inherit the environment of the top level store(s) so they need to have an environment object passed explicitly if they need to access other stores in their methods.
   *
   * @returns An environment object
   */
  newEnv(): StoreEnv {
    return { storeManager: this };
  }
}

/**
 * A React hook for accessing/creating the given store spec's instance.
 *
 * Note: This is identical to `useContext(StoreContext).getStore(SomeStore)`
 * @param {*} store An MST store spec which may or may not already have been instantiated for this app session.
 * @returns A newly created instance if one had not yet been created, otherwise the previously created instance.
 */
export const useStore = <P extends ModelProperties, O, C, S>(
  store: IModelType<P, O, C, S> &
    CustomInitStore<Instance<IModelType<P, O, C, S>>>
): Instance<IModelType<P, O, C, S>> => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw Error(
      "Attempted to get store from context from outside of a context provider"
    );
  }
  return context.getStore(store);
};

/**
 * A React hook for accessing a preference by key. This is a shortcut for `useStore(PreferencesStore).get(key)`.
 * @param key The key to look up a preference for.
 * @returns The located preference, or undefined if no match was found.
 */
export const usePreference = <K extends keyof Instance<typeof AllPrefs>>(
  key: K
): Instance<typeof AllPrefs>[K]["value"] => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw Error(
      "Attempted to get preference from context from outside of a context provider"
    );
  }
  return context.getPreference(key);
};
