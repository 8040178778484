import { Http } from "api/Http";

const Api = new Http("references/1");

class ReferencesApi {
  constructor(api) {
    this.api = api;
  }

  async fetchOffices() {
    let res = await this.api.get("/offices");
    return res.data || [];
  }

  async fetchPhoneTypes() {
    let res = await this.api.get("/phoneTypes");
    return res.data || [];
  }

  async fetchFeeSchedules() {
    let res = await this.api.get("/feeSchedules");
    return res.data || [];
  }

  async fetchLanguages() {
    let res = await this.api.get("/languages");
    return res.data || [];
  }

  async fetchMaritalStatuses() {
    let res = await this.api.get("/maritalStatuses");
    return res.data || [];
  }

  async fetchEmploymentStatuses() {
    let res = await this.api.get("/employmentStatuses");
    return res.data || [];
  }

  async fetchPatientRelationships() {
    let res = await this.api.get("/patientRelationships");
    return res.data || [];
  }
}

const newApi = new ReferencesApi(Api);
export default newApi;
