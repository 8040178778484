import { flow, types } from "mobx-state-tree";
import Api from "./Api";

export const UserModel = types.model("UserModel", {
  userId: types.number,
  uniqueId: types.string,
  firstName: types.string,
  lastName: types.string,
  professionalTitle: types.string,
  auditId: types.maybe(types.string)
});

const Store = types
  .model("UsersStore", {
    users: types.maybeNull(types.array(UserModel)),
    providers: types.maybeNull(types.array(UserModel))
  })
  .actions(self => ({
    getUsers: flow(function*() {
      if (!self.users) self.users = yield Api.fetchUsers();
      return self.users;
    }),

    /**
     * Returns the logged in user by finding the object by id
     *
     * @param {Number} userId | Id of logged in user
     * @return {Object}
     */
    getUser: flow(function*(userId) {
      yield self.getUsers();
      return self.users.find(u => u.userId === userId);
    }),

    getProviders: flow(function*() {
      if (!self.providers) self.providers = yield Api.fetchProviders();
      return self.providers;
    }),

    forSelect: flow(function*(which) {
      switch (which) {
        case "users": {
          let users = yield self.getUsers();
          return self.mapUsers(users);
        }

        case "providers": {
          let providers = yield self.getProviders();
          return self.mapUsers(providers);
        }

        default: {
          console.log("Invalid 'forSelect' parameter for Users.js");
          break;
        }
      }
    })
  }))
  .views(self => ({
    providerById(providerId) {
      if (!self.providers) {
        return null;
      }
      return self.providers.find(p => p.userId === providerId);
    },

    /**
     * Bypassing async/await calls from the order mst store
     *
     * The order form has already loaded the users
     *
     * @param {Number} userId
     * @return {Object}
     */
    userById(userId) {
      if (!self.users) {
        return null;
      }
      return self.users.find(u => u.userId === userId);
    },

    mapUsers(users) {
      let data = [];
      if (Array.isArray(users)) {
        users.forEach(u =>
          data.push({
            id: u.userId.toString(),
            name: u.firstName + " " + u.lastName
          })
        );
      }
      return data;
    }
  }));

export default Store;
