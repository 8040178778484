import Api from "../Api";
import DataForFormSelect from "utils/DataForFormSelect";
import { flow, types } from "mobx-state-tree";

const SchedulingResource = types.model("SchedulingResource", {
  name: types.maybe(types.string),
  resourceId: types.number,
  providerId: types.maybe(types.number)
});

const AppointmentStatus = types.model("AppointmentStatus", {
  appointmentStatus: types.string,
  appointmentStatusId: types.number
});

const AppointmentType = types.model("AppointmentType", {
  appointmentType: types.maybe(types.string),
  appointmentTypeId: types.number
});

const Store = types
  .model("ApptFormOptions", {
    resources: types.maybeNull(types.array(SchedulingResource)),
    statuses: types.maybeNull(types.array(AppointmentStatus)),
    types: types.maybeNull(types.array(AppointmentType))
  })
  .actions(self => ({
    getResources: flow(function*() {
      if (!self.resources) self.resources = yield Api.fetchResources();
      return self.resources || [];
    }),

    getStatuses: flow(function*() {
      if (!self.statuses) self.statuses = yield Api.fetchAppointmentStatuses();
      return DataForFormSelect(
        self.statuses || [],
        "appointmentStatusId",
        "appointmentStatus"
      );
    }),

    getTypes: flow(function*() {
      if (!self.types) self.types = yield Api.fetchAppointmentTypes();
      return DataForFormSelect(
        self.types || [],
        "appointmentTypeId",
        "appointmentType"
      );
    })
  }));

export default Store;
