/**
 * RenderOrders.js
 *
 * Wrapper to include the handler and ui component for dashboard orders
 */
import React from "react";
import OrdersHandler from "../handlers/OrdersHandler";
import Orders from "./components/Orders";

const RenderOrders = () => (
  <OrdersHandler>
    <Orders />
  </OrdersHandler>
);

export default RenderOrders;
