import { Http } from "api/Http";

const Api = new Http("patients/1/patient");

class ProblemsApi {
  constructor(api) {
    this.api = api;
  }

  getProblems = async patientId => {
    let res = await this.api.get("/", patientId + "/problem");
    return res.data || [];
  };
}

const newApi = new ProblemsApi(Api);
export default newApi;
