/**
 * Referral.js
 *
 * HOC for Referral UI
 */
import React from "react";
import PropTypes from "prop-types";
import ReferralHandler from "../handlers/ReferralHandler";
import Referral from "./components/Referral";

const ReferralPage = ({ match }) => (
  <ReferralHandler referralId={parseInt(match.params.referralId)}>
    <Referral />
  </ReferralHandler>
);

ReferralPage.propTypes = {
  match: PropTypes.object
};

export default ReferralPage;
