import React from "react";
import { LoginProvider } from "./state/LoginProvider";
import StayAliveHandler from "./handlers/StayAliveHandler";
import EventsHandler from "./handlers/EventsHandler";
import RenderLogoutAlert from "./views/RenderLogoutAlert";
import RenderLoginDialog from "./views/RenderLoginDialog";

const Index = () => (
  <LoginProvider>
    <StayAliveHandler />
    <EventsHandler />
    <RenderLogoutAlert />
    <RenderLoginDialog />
  </LoginProvider>
);

export default Index;
