import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import References from "services/references/References";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import UIProgress from "components/ui/UIProgress";
import { FormHelperText, Theme } from "@material-ui/core";
import { useStore } from "utils/useStore";
import { WithStyles } from "@material-ui/styles";

const styles = (theme: Theme) => ({
  selectField: {
    marginBottom: 10,
    background: theme.palette.common.white + " !important;",
    borderRadius: "4px !important;"
  },
  formControl: {
    width: "100%"
  }
});

interface Props extends WithStyles<typeof styles> {
  showFeeScheduledRequiredError: boolean;
  setFeeScheduleInStore: (feeScheduleId: number) => void;
  selectedFeeScheduleId: number | undefined;
}

const FeeScheduleSelectField = ({
  showFeeScheduledRequiredError,
  setFeeScheduleInStore,
  classes,
  selectedFeeScheduleId
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [feeSchedules, setFeeSchedules] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const references = useStore(References);

  const populateFeeSchedules = useCallback(async () => {
    const feeSchedules = await references.forSelect("feeSchedules");
    if (feeSchedules !== undefined) {
      setFeeSchedules(feeSchedules);
    }
    setLoading(false);
  }, [references]);

  useEffect(() => {
    populateFeeSchedules();
  }, [populateFeeSchedules]);

  const handleChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const feeScheduleName = event.target.value as string;
    const feeScheduleId = getFeeScheduleId(feeScheduleName);
    setFeeScheduleInStore(feeScheduleId);
  };

  const getFeeScheduleId = (feeScheduleName: string) => {
    const matchingFeeScheduleObject = feeSchedules.find(feeSchedule => {
      return feeSchedule.name === feeScheduleName;
    });
    return Number(matchingFeeScheduleObject?.id);
  };

  const menuItems = feeSchedules.map(feeSchedule => {
    return (
      <MenuItem value={feeSchedule.name} key={feeSchedule.id}>
        {feeSchedule.name}
      </MenuItem>
    );
  });

  const selectedFeeSchedule = feeSchedules.find(feeSchedule => {
    return feeSchedule.id === selectedFeeScheduleId?.toString();
  })?.name;

  if (loading) return <UIProgress />;
  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel
          htmlFor="feeScheduleId"
          error={showFeeScheduledRequiredError}
        >
          Fee Schedule
        </InputLabel>
        <Select
          className={classes.selectField}
          value={selectedFeeSchedule}
          disableUnderline={true}
          fullWidth={true}
          onChange={handleChange}
          input={<FilledInput name="feeScheduleId" id="feeScheduleId" />}
          required
        >
          {menuItems}
        </Select>
        {showFeeScheduledRequiredError && (
          <FormHelperText error={showFeeScheduledRequiredError}>
            {"Fee Schedule is required"}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(FeeScheduleSelectField);
