import React from "react";
import { observer } from "mobx-react";
import UINotFound from "components/ui/UINotFound";
import ApptCardList from "./ApptCardList";
import ApptStore from "services/appointments/store/DataStore";
import UIStore from "../store/UIStore";
import UIProgress from "components/ui/UIProgress";
import ApptCalendarDayView from "./ApptCalendarDayView";
import { StoreContext } from "utils/useStore";
import Preferences from "services/preferences/Store";

class Appointments extends React.Component {
  static contextType = StoreContext;

  get uiStore() {
    return this.context.getStore(UIStore);
  }

  componentDidMount() {
    this.uiStore.fetchShowDayViewPref(this.context.getStore(Preferences));
  }

  render() {
    let { loading, appts } = this.context.getStore(ApptStore);
    let filters = this.context.getPreference("apptFilters");
    let { showDayView, prefsLoaded } = this.uiStore;
    if (loading || !prefsLoaded) {
      return <UIProgress />;
    }

    if (filters) {
      let resources = filters["resources"];
      if (resources && resources.length === 0) {
        return (
          <div className="padding">
            <UINotFound message="No Resources" />
          </div>
        );
      }
    }

    if (showDayView) {
      return <ApptCalendarDayView />;
    } else {
      return <ApptCardList appts={appts} />;
    }
  }
}

export default observer(Appointments);
