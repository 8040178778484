import { types } from "mobx-state-tree";
import Provider from "./Provider";
import Office from "./Office"
import FeeSchedule from "./FeeSchedule";

const ProviderOfficeFeeDataModel = {
  patientProvider: types.optional(Provider, {}),
  accountProvider: types.optional(Provider, {}),
  patientOffice: types.optional(Office, {}),
  feeSchedule: types.optional(FeeSchedule, {}),
  accountOffice: types.optional(Office, {})
};
export default ProviderOfficeFeeDataModel;
